// for localhost development
// export let BASE_URL = "https://wafflecart.petalyellow.com/testing";
export let BASE_URL = "https://way2waffleapi.petalyellow.com";
// export let BASE_URL = "https://wafflecart.petalyellow.com/testing/public";

//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   "/public";

//for localhost production
// export let BASE_URL =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   `${
//     window.location.port !== ""
//       ? `:${window.location.port}/tgss/laravel`
//       : "/tgss/laravel"
//   }`;
//for tgss.com
// export let BASE_URL =
//   window.location.protocol + "//" + window.location.hostname + "/laravel";

// for production
// export let BASE_URL =
//   window.location.protocol +
//   "//" +
//   window.location.hostname +
//   `${
//     window.location.port !== ""
//       ? `:${window.location.port}`
//       : `${!window.location.href.includes(".test") ? "/public" : ""}`
//   }`;
